import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { BackButton, NextButton } from '@envy/ArrowButtons';
import * as styles from './Amenities.module.scss';

const tabList = [
  'BIERGARTEN',
  'COWORKING',
  'CAFÉ',
  'ROOFTOP',
  'ZEN GARDEN',
  'GYM',
  'MORE',
];

const carouselContent = [
  {
    title: 'Biergarten',
    content: (
      <p>Dine like Dionsyius in our beer garden. Taps are flowing with local brews and international favorites. Whether you toast with friends or sip in solitude, libations here are the talk of the town.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/biergarten.jpeg"
        className={styles.image}
        alt="people drinking beer"
      />
    ),
  },
  {
    title: 'Coworking',
    content: (
      <p>Our thoughtful coworking spaces are designed with the aesthetics of work-from-home convenience and office productivity.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/coworking.jpeg"
        className={styles.image}
        alt="coworking room"
        formats={["auto"]}
        quality={100}
      />
    ),
  },
  {
    title: 'Café',
    content: (
      <p>Residents and their guests will feel gratified in our elegant cafe. Coffee, cocktails, and excellent cuisine are all served with sophistication and flare.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/cafe.jpeg"
        className={styles.image}
        alt="yellow drink"
      />
    ),
  },
  {
    title: 'Rooftop',
    content: (
      <p>Bright lights. Big city. Lucky you. Our rooftop was created for residents to rest, relax, socialize, and celebrate all of life’s special moments at Envy.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/rooftop.jpeg"
        className={styles.image}
        alt="people on a rooftop"
      />
    ),
  },
  {
    title: 'Zen Garden',
    content: (
      <p>Breath in, exhale, and repeat. This serene garden features tranquil spaces for yoga, meditation, or a quiet stroll. Find your Zen in every season in Envy’s all-weather garden.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/zengarden.jpeg"
        className={styles.image}
        alt="leaf with rain drops"
      />
    ),
  },
  {
    title: 'Gym',
    content: (
      <p>Envy recognizes that a privileged life is a balanced life. Our gym is outfitted with state of the art equipment, MIRROR Technology by Lululemon, and space to stretch. The gym also features specialized fitness classes throughout the year.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/gym.jpeg"
        className={styles.image}
        alt="people working out"
      />
    ),
  },
  {
    title: 'Additional Amenities',
    content: (
      <p>- Reserved Indoor Parking Space<br/>- Electric Vehicle Charging Capabilities<br/>- Club Room & Movie Screening<br/>- Remote Entry Access and Smart Door Locks<br/>- Secured Package System by Package Concierge<br/>- Pet Washing Station<br/>- Bicycle Storage</p>
    ),
    image: (
      <StaticImage
        src="../../../images/envy/Amenities/evcharging.jpeg"
        className={styles.image}
        alt="eletric car charging"
      />
    ),
  },
];

function Amenities() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cardLeftPos, setCardLeftPos] = useState(0);

  const calculateLeft = (index = selectedTab) => {
    if (index === 0) {
      return setCardLeftPos(window.innerWidth < 1066 ? 0 : 113);
    }
    const width =
      window.innerWidth < 1214 ? window.innerWidth : window.innerWidth;
    const card = document.getElementsByClassName(styles.card)[0];
    const cardWidth = card.scrollWidth;
    const left = (width - cardWidth) / 2 - (cardWidth + 45) * index;

    setCardLeftPos(left);
  };

  const handleSelection = e => {
    const index = parseInt(e.currentTarget.dataset.index);

    setSelectedTab(index);
    calculateLeft(index);
  };

  const goToTab = tabIndex => {
    const newIndex =
      tabIndex < 0 ? tabList.length - 1 : tabIndex % tabList.length;

    setSelectedTab(newIndex);
    calculateLeft(newIndex);
  };

  useEffect(() => {
    calculateLeft();
  }, []);

  return (
    <div className={classNames(styles.relativeWrapper, styles.dark)} style={{ padding: 0 }}>
      <div className={styles.container} id="amenities">
        <div className={styles.textContainer}>
          <h3>Amenities at Envy</h3>
          <h2>
            Live insouciantly
          </h2>
          <p>Resident-only amenities are at your fingertips. Indulge and imbibe, refresh and rejuvenate, gratify and aggrandize your living. Live, work, and play at Envy.
          </p>
        </div>

        <div className={styles.carouselContainer}>
          <div className={styles.tabs}>
            {tabList.map((tabTitle, index) => (
              <div
                key={index}
                onClick={handleSelection}
                className={classNames(styles.tab, {
                  [styles.selected]: index === selectedTab,
                })}
                data-index={index}
              >
                <div className={styles.backgroundImage} />
                <div className={styles.foregroundImage} />
                <span>{tabTitle}</span>
              </div>
            ))}
          </div>

          <div className={styles.mobileTabs}>
            <p>AMENITIES</p>
            <div className={styles.decorLine} />
          </div>

          <div className={styles.carousel}>
            {
              selectedTab > 0 &&
              <BackButton
                page={selectedTab}
                pageCount={tabList.length}
                onClick={() => goToTab(selectedTab - 1)}
                className={classNames(styles.arrow, styles.backButton)}
                small
              />
            }
            {
              selectedTab < tabList.length - 1 &&
              <NextButton
                page={selectedTab}
                pageCount={tabList.length}
                onClick={() => goToTab(selectedTab + 1)}
                className={classNames(styles.arrow, styles.nextButton)}
                small
              />
            }
            <div
              className={styles.cardsWrapper}
              style={{ left: `${cardLeftPos}px` }}
            >
              {carouselContent.map((data, index) => (
                <div key={index} className={styles.card}>
                  {data.image}
                  <div className={styles.info}>
                    <div className={styles.content}>
                      <span>{data.title}</span>
                      <div className={styles.divider} />
                      {data.content}
                    </div>
                    <div className={styles.microLogo}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="81"><path fill="#eeac88" d="M0 0v81h81V0Zm80.412 70.369H58.66V59.113L69.189 40.43h-.684l-10.2 18.049-13.976-17.944-.08-.1h-6.245V11.679l21.868 28.669.081.105h.86V.588h19.594ZM60.233.588v31.886L43.317 10.043h-5.914v20.572l-.006.239c-.208 8.982-6.406 8.983-12.377 8.982h-7.749V25.414h.063c3.021 0 5.033.981 5.033 5.77v.267h.606V18.739h-.606v.267c0 5.395-2.086 5.812-5.033 5.812h-.063V10.64h15.415v-.6H.586V.588ZM38.008 40.433v22.43L21.169 40.536l-.079-.1ZM.589 10.633h11.227v29.8h2.708l23.133 30.306.365.489v9.187H.589Zm38.02 69.784V70.86l.011-.014V42.052l14.583 18.5v10.406h27.21v9.458Z" data-name="Path 4930"/></svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', right: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor8.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', left: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor9.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      /> */}
    </div>
  );
}

export default Amenities;
